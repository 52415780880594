<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        When I started off my journey to becomming a programmer, I got really
        intimidated by my classmates I was looking up to. However, I never
        pictured myself as a developer because I didn't have any role models
        that I could really relate to.
      </p>
      <br />
      <p>
        Then, I found <strong>Ladies of Code</strong>. A community of passionate
        and talented women in STEM, devoted to inspiring students like myself to
        persue carreers in Computer Engineering.
      </p>
      <br />
    </div>
    <div class="half-section">
      <p>
        The Paris based community of more than two thousand members organizes
        meet-ups and tech-talks, and as a newbie I couldn't have asked for a
        better environment to develop my skills.
      </p>
      <br />
      <p>
        For 6 months I created content for the Instgram page of Ladies of Code
        using <strong>Figma</strong> and Facebook's Business planning tools.
      </p>
      <p>
        The most challenging aspect of this project was creating relateable and
        enjoyeable content that would encourage interaction.
      </p>
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/ladies/album/9.png" alt="" />
    </div>
    <div class="full-section half-section-media ">
      <img src="@/assets/projects/ladies/album/5.png" alt="" />
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/ladies/album/7.png" alt="" />
    </div>
    <div class="half-section">
      <p>
        The most scary part of it all : creating original developer memes, while
        just starting of my developer learning journey myself !
      </p>
      <p>
        However I found that the intensive hours of developer memes on Reddit
        taught me more than some courses of my
        <router-link to="/about" class="links animated-links">
          Computer Engineering degree
        </router-link>
        did...
      </p>
    </div>

    <div class="half-section">
      <p>
        The most interesting project I got to do though, was organizing an event
        for <strong>International Women's Day</strong> at my school, inviting
        developers from Ladies of Code and alumni thriving as women in a male
        dominated industry.
      </p>
      <p>
        We got to talk on stage, and exchange about their life experiences and
        advice for women and girls curious about coding like myself.
      </p>
      <p>This was a truly inspiring experience !</p>
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/ladies/album/8.png" alt="" />
    </div>
    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        The consistency of creating and posting content at least 3 times a week
        allowed me to learn <strong>discipline</strong> and personal
        organization to make it work.
      </p>
      <p>
        I also learned the value of
        <strong>surrounding yourself with talented people</strong> in order to
        make progress. Having a mentor and constantly asking for advice and
        feedback from the women from Ladies of coode, was the best thing that
        happened to me when I started coding !
      </p>
      <br />
      <p>
        Making <strong>memes</strong> on relateable subjects for developers
        taught me how to stay up to date on relevant tech subjects, and also how
        to engage in a team of developers. (Feel free to send me developer memes
        on <a href="" class="links animated-links">Twitter</a>)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ladies",
};
</script>
<style scoped></style>
