var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ladies project-component"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"half-section"},[_c('p',[_vm._v(" The most scary part of it all : creating original developer memes, while just starting of my developer learning journey myself ! ")]),_c('p',[_vm._v(" However I found that the intensive hours of developer memes on Reddit taught me more than some courses of my "),_c('router-link',{staticClass:"links animated-links",attrs:{"to":"/about"}},[_vm._v(" Computer Engineering degree ")]),_vm._v(" did... ")],1)]),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullsection"},[_c('p',[_vm._v(" When I started off my journey to becomming a programmer, I got really intimidated by my classmates I was looking up to. However, I never pictured myself as a developer because I didn't have any role models that I could really relate to. ")]),_c('br'),_c('p',[_vm._v(" Then, I found "),_c('strong',[_vm._v("Ladies of Code")]),_vm._v(". A community of passionate and talented women in STEM, devoted to inspiring students like myself to persue carreers in Computer Engineering. ")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"half-section"},[_c('p',[_vm._v(" The Paris based community of more than two thousand members organizes meet-ups and tech-talks, and as a newbie I couldn't have asked for a better environment to develop my skills. ")]),_c('br'),_c('p',[_vm._v(" For 6 months I created content for the Instgram page of Ladies of Code using "),_c('strong',[_vm._v("Figma")]),_vm._v(" and Facebook's Business planning tools. ")]),_c('p',[_vm._v(" The most challenging aspect of this project was creating relateable and enjoyeable content that would encourage interaction. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"half-section half-section-media"},[_c('img',{attrs:{"src":require("@/assets/projects/ladies/album/9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-section half-section-media"},[_c('img',{attrs:{"src":require("@/assets/projects/ladies/album/5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"half-section half-section-media"},[_c('img',{attrs:{"src":require("@/assets/projects/ladies/album/7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"half-section"},[_c('p',[_vm._v(" The most interesting project I got to do though, was organizing an event for "),_c('strong',[_vm._v("International Women's Day")]),_vm._v(" at my school, inviting developers from Ladies of Code and alumni thriving as women in a male dominated industry. ")]),_c('p',[_vm._v(" We got to talk on stage, and exchange about their life experiences and advice for women and girls curious about coding like myself. ")]),_c('p',[_vm._v("This was a truly inspiring experience !")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"half-section half-section-media"},[_c('img',{attrs:{"src":require("@/assets/projects/ladies/album/8.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lesson-box"},[_c('h2',[_vm._v("What did I learn ?")]),_c('br'),_c('p',[_vm._v(" The consistency of creating and posting content at least 3 times a week allowed me to learn "),_c('strong',[_vm._v("discipline")]),_vm._v(" and personal organization to make it work. ")]),_c('p',[_vm._v(" I also learned the value of "),_c('strong',[_vm._v("surrounding yourself with talented people")]),_vm._v(" in order to make progress. Having a mentor and constantly asking for advice and feedback from the women from Ladies of coode, was the best thing that happened to me when I started coding ! ")]),_c('br'),_c('p',[_vm._v(" Making "),_c('strong',[_vm._v("memes")]),_vm._v(" on relateable subjects for developers taught me how to stay up to date on relevant tech subjects, and also how to engage in a team of developers. (Feel free to send me developer memes on "),_c('a',{staticClass:"links animated-links",attrs:{"href":""}},[_vm._v("Twitter")]),_vm._v(") ")])])
}]

export { render, staticRenderFns }